import moment from 'moment';

function handleMultipleSeparators(str: string): number {
  const parts = str.split(/[.,]/);
  const dots = (str.match(/\./g) || []).length;
  const commas = (str.match(/,/g) || []).length;

  // All separators are the same type
  if (commas === 0 || dots === 0) {
    const lastPart = parts[parts.length - 1];

    // If last part is less than 3 digits, treat it as decimal
    if (lastPart.length < 3) {
      const entireNumber = parts.slice(0, -1).join('');
      return Number(entireNumber + '.' + lastPart);
    } else {
      // Otherwise, treat all as thousand separators
      return Number(parts.join(''));
    }
  }

  // Different separators - last part is decimal
  const wholeNumber = parts.slice(0, -1).join('');
  return Number(wholeNumber + '.' + parts[parts.length - 1]);
}

function handleSingleSeparator(str: string): number {
  const parts = str.split(/[.,]/);
  const isComma = str.includes(',');
  const isSeparatorPresent = isComma || str.includes('.');

  // It's a dot
  if (parts.length === 2 && isSeparatorPresent) {
    if (parts[0].length > 3) {
      // Treat as decimal
      return Number(str.replace(',', '.'));
    } else {
      // Check if there are exactly 3 digits after the dot
      if (parts[1].length === 3) {
        // Treat as thousands
        return Number(parts.join(''));
      } else {
        // Treat as decimal
        return Number(str.replace(',', '.'));
      }
    }
  }

  return Number(str);
}

export function amountsParser(str: string): number | null {
  if (!str) {
    return null;
  }

  // Count occurrences of each separator
  const dots = (str.match(/\./g) || []).length;
  const commas = (str.match(/,/g) || []).length;

  // Case 1: Multiple separators
  if (dots + commas > 1) {
    return handleMultipleSeparators(str);
  }

  // Case 2: Single separator
  if (dots + commas === 1) {
    return handleSingleSeparator(str);
  }

  // No separators
  return Number(str);
}


const formats = [
  'DD MM YYYY',
  'D M YYYY',
  'DD MM YY',
  'D M YY',
  'D MM YYYY',
  'DD M YYYY',
  'D MM YY',
  'DD M YY',
  'DD MMM YYYY',
  'D MMM YYYY',
  'DD MMM YY',
  'D MMM YY',
  'DD MMMM YYYY',
  'D MMMM YYYY',
  'YYYY MM DD',
  'MMMM DD YYYY',
  'MMMM D YYYY',
  'MMMM DD YY',
  'MMMM D YY',
  'MMM DD YYYY',
  'MMM D YYYY',
  'MMM DD YY',
  'MMM D YY',
  'MMM DDYYYY',
  'MMMDDYYYY',
  'MMMDD YYYY'
];

/**
 * parse a string value as date using moment
 */
export function dateParser(dateStr: string) {
  const normalizedDateStr = dateStr.toUpperCase().trim(); // Standardize to uppercase

  const fullMonthRegex =
    /JANVIER|FÉVRIER|MARS|AVRIL|MAI|JUIN|JUILLET|AOÛT|SEPTEMBRE|OCTOBRE|NOVEMBRE|DÉCEMBRE/;

  const abbreviatedMonthRegex =
    /JANV|FÉVR|MARS|AVR|MAI|JUIN|JUIL|AOÛT|SEPT|OCT|NOV|DÉC/;

  let locale: 'fr' | 'en' = 'en';

  if (fullMonthRegex.test(normalizedDateStr) || abbreviatedMonthRegex.test(normalizedDateStr)) {
    locale = 'fr';
  }

  moment.locale(locale);

  // Parse the date
  const parsedDate = moment(normalizedDateStr, formats, true);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
}
